import { useEffect, useState } from 'react';
import React from 'react';
import { Pipeline, Prompt } from '../../types';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { getErrorMessage } from '../../common/utils';
import { deletePrompt, getPrompt, getPromptPipelines, removePromptUser, updatePrompt } from '../../services/Prompts';
import DataTable, { TableData } from '../../components/common/Datatable';
import Selector, { SelectorValue } from '../../components/common/Selector';
import { PromptUser, Roles } from '../../types';
import { getUserLocal } from '../../services/User';
import { AddUserModal, StyledDialog } from '../../components';
import { managePromptUser } from '../../services/Prompts';
import { formatRole, isLibraryItem, userIsOwner } from '../../common/prompts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  faSkullCrossbones,
  faSpinner,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Skeleton from 'react-loading-skeleton';

interface Props {}

export const manageUserSelectorValues: { [key in Roles]: SelectorValue } = {
  [Roles.OWNER]: { value: Roles.OWNER, label: 'Owner' },
  [Roles.READ_WRITE]: { value: Roles.READ_WRITE, label: 'Contributor' },
  [Roles.READ_ONLY]: { value: Roles.READ_ONLY, label: 'Read Only' },
  [Roles.ADMIN]: { value: Roles.ADMIN, label: 'Admin' }
};

/**
 * Manage prompt page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ManagePrompt: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const promptId = useParams().promptId;
  const user = getUserLocal();

  const [selectedUser, setSelectedUser] = useState<PromptUser>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [pipelines, setPipelines] = useState<Pipeline[]>();
  const [tableData, setTableData] = useState<TableData>();
  const [canManage, setCanManage] = useState<boolean>();
  const [deleteConfirmText, setDeleteConfirmText] = useState<string>('');
  const [pipelineSelectors, setPipelineSelectors] = useState<SelectorValue[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPromptArchiving, setIsPromptArchiving] = useState<boolean>(false);
  const [isShowAddUser, setIsShowAddUser] = useState<boolean>(false);
  const [isShowRemoveModal, setIsShowRemoveModal] = useState<boolean>(false);
  const [isShowArchiveModal, setIsShowArchiveModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isPromptDeleting, setIsPromptDeleting] = useState<boolean>(false);
  const [isSavingDefaultPipeline, setIsSavingDefaultPipeline] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      await loadData();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!prompt) return;

    // const multipleOwners = prompt.users.filter((u) => u.promptRole === Roles.OWNER).length > 1;
    setTableData({
      headers: ['Name', 'Email', 'Role', ''],
      rows: prompt.users.map((user) => ({
        cols: [
          { value: user.name },
          { value: user.email },
          { value: generateActionsSelector(user) },
          {
            value:
              !canManage || user.id === getUserLocal()?.id ? (
                ''
              ) : (
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="h-5 w-5 text-red-500 hover:text-red-400 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-200"
                  onClick={() => {
                    setSelectedUser(user);
                    setIsShowRemoveModal(true);
                  }}
                />
              )
          }
        ]
      }))
    });
  }, [prompt]);

  const loadData = async () => {
    try {
      const [_prompt, _pipelines] = await Promise.all([getPrompt(promptId!), getPromptPipelines(promptId!)]);
      setPrompt(_prompt);
      setPipelines(_pipelines);
      setPipelineSelectors([
        { value: '', label: 'None' },
        ..._pipelines.map((pipeline) => ({ value: pipeline.id, label: pipeline.name }))
      ]);
      if (_prompt.defaultPipeline && _prompt.defaultPipeline.id) {
        setSelectedPipeline(_pipelines.find((pipeline) => pipeline.id === _prompt.defaultPipeline?.id));
      }
      setCanManage(userIsOwner(_prompt, getUserLocal()!));
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    }
  };

  const handleArchive = async () => {
    if (!prompt) return;
    setIsPromptArchiving(true);

    try {
      await updatePrompt(
        prompt.id!,
        prompt.name,
        prompt.description,
        prompt.tags,
        !prompt.archived,
        prompt.type,
        prompt.defaultPipeline?.id
      );
      await loadData();
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsPromptArchiving(false);
      setIsShowArchiveModal(false);
    }
  };

  const generateActionsSelector = (au: PromptUser) => {
    return user!.id === au.id || !canManage ? (
      <span>{formatRole(au.promptRole)}</span>
    ) : (
      <Selector
        key={au.id}
        classNames="w-40 !hover:z-0"
        values={Object.values(manageUserSelectorValues).filter((value) => value.value !== Roles.ADMIN)}
        defaultValue={manageUserSelectorValues[au.promptRole]}
        onChange={(selectorValue) => onRoleChange(au, selectorValue)}
      />
    );
  };

  const onRoleChange = async (user: PromptUser, roleSelector: SelectorValue) => {
    setIsLoading(true);

    try {
      await managePromptUser(promptId!, user.id, 'SET_ROLE', roleSelector.value as Roles);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = (promptUser: PromptUser) => {
    setIsShowAddUser(false);

    if (!prompt) return;

    setPrompt({ ...prompt, users: prompt.users.concat(promptUser) });
  };

  const handleOnRemove = async () => {
    setIsLoading(true);
    try {
      await removePromptUser(promptId!, selectedUser!.id);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsLoading(false);
      setIsShowRemoveModal(false);
    }

    setPrompt({ ...prompt!, users: prompt!.users.filter((u) => u.id !== selectedUser!.id) });
  };

  const handleOnDelete = async () => {
    if (deleteConfirmText !== prompt?.name) return;

    setIsPromptDeleting(true);

    try {
      await deletePrompt(promptId!);
    } catch (e) {
      return toast.error(getErrorMessage(e));
    } finally {
      setIsPromptDeleting(false);
      setIsShowDeleteModal(false);
      navigate(`/prompts/list`);
    }
  };

  const handlePipelineChange = (value: SelectorValue) => {
    if (!prompt) return;

    const pipeline = pipelines?.find((pipeline) => pipeline.id === value.value);

    setSelectedPipeline(pipeline);
    setIsSavingDefaultPipeline(true);

    (async () => {
      try {
        await updatePrompt(
          prompt.id!,
          prompt.name,
          prompt.description,
          prompt.tags,
          prompt.archived,
          prompt.type,
          (value.value as string).length ? (value.value as string) : undefined
        );
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsSavingDefaultPipeline(false);
      }
    })();
  };

  return (
    <>
      <div className="mx-auto max-w-2xl">
        <div className="flex">
          <h1 className="text-2xl flex-1 font-semibold leading-7 text-gray-800">{prompt?.name}</h1>
          <a href="/help/#/prompt_manage" target="_blank" rel="noreferrer">
            <button className="help">
              <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
            </button>
          </a>
        </div>
        <div className="mt-8">
          <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800 mb-2">Status</h2>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div className="text-sm">
              This prompt is currently
              <span className="font-bold text-indigo-600"> {prompt?.archived ? 'archived' : 'active'}</span>.
            </div>
          )}
        </div>
        <div className="mt-8">
          <div className="flex items-center">
            <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800">Users</h2>
            {canManage && !isLibraryItem(prompt) && (
              <button disabled={isLoading} onClick={() => setIsShowAddUser(true)}>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="h-7 w-7 text-indigo-600 hover:text-indigo-500 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-200"
                />
              </button>
            )}
          </div>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Other users who can access this prompt and their roles.
          </p>
          <div className="col-span-full">
            {isLibraryItem(prompt) ? (
              <div className="text-xs text-gray-700">Library prompt&apos;s are available to all Promptly users.</div>
            ) : isLoading ? (
              <Skeleton count={5} />
            ) : (
              <DataTable data={tableData} />
            )}
          </div>
        </div>
        <div className="border-b border-gray-900/10 py-12 text-gray-600 text-sm">
          <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800 mb-2">Default Pipeline</h2>
          <p className="mt-1 leading-6  mb-2">Used for tracking evaluation of this prompt.</p>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div>
              {pipelines?.length && !isLoading ? (
                <Selector
                  values={pipelineSelectors}
                  onChange={handlePipelineChange}
                  defaultValue={{ value: selectedPipeline?.id!, label: selectedPipeline?.name! }}
                  disabled={isLoading}
                  isSearchable={true}
                  loading={isSavingDefaultPipeline}
                  classNames="w-80 mb-4"
                />
              ) : (
                <div>
                  There are no pipelines available for this prompt. <a href="/pipelines">Click here</a> to add one.
                </div>
              )}
            </div>
          )}
        </div>
        {canManage && (
          <div className="mt-8">
            <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800">Actions</h2>
            <div className="mt-2">
              <button className="standard" onClick={() => setIsShowArchiveModal(true)}>
                {prompt?.archived ? 'Restore' : 'Archive'}
              </button>
              <button className="danger ml-4" onClick={() => setIsShowDeleteModal(true)}>
                Delete
              </button>
            </div>
          </div>
        )}
      </div>

      {prompt && canManage && (
        <AddUserModal isOpen={isShowAddUser} onSave={onSave} prompt={prompt} onClose={() => setIsShowAddUser(false)} />
      )}

      <StyledDialog
        isOpen={isShowRemoveModal}
        title="Remove User"
        closeText="Cancel"
        confirmText={
          isLoading ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
              Removing
            </div>
          ) : (
            'Remove'
          )
        }
        confirmClassName="bg-red-500 hover:!bg-red-400"
        icon={faTrashCan}
        width="w-1/4"
        disabled={isLoading}
        onClose={() => setIsShowRemoveModal(false)}
        onConfirm={handleOnRemove}>
        <div>
          <p>Are you sure you want to remove {selectedUser?.name} from this prompt?</p>
        </div>
      </StyledDialog>

      <StyledDialog
        isOpen={isShowArchiveModal}
        title={prompt?.archived ? 'Restore Prompt' : 'Archive Prompt'}
        closeText="Cancel"
        confirmText={
          isPromptArchiving ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
              {prompt?.archived ? 'Restoring' : 'Archiving'}
            </div>
          ) : prompt?.archived ? (
            'Restore'
          ) : (
            'Archive'
          )
        }
        confirmClassName={prompt?.archived ? '' : `!bg-red-500 hover:!bg-red-400`}
        icon={faTriangleExclamation}
        width="w-1/3"
        disabled={isPromptArchiving}
        onClose={() => setIsShowArchiveModal(false)}
        onConfirm={() => handleArchive()}>
        <div className="w-full">
          {prompt?.archived ? (
            <div>Are you sure you want to restore this prompt?</div>
          ) : (
            <div>
              Are you sure you want to archive this prompt?
              <ul className="list-disc ml-4 mt-4 text-sm">
                <li>Clients will not be able to request this prompt or its versions.</li>
                <li>Telemetry will no longer be collected.</li>
              </ul>
            </div>
          )}
        </div>
      </StyledDialog>

      <StyledDialog
        isOpen={isShowDeleteModal}
        title="Delete Prompt"
        closeText="Cancel"
        confirmText={
          isPromptDeleting ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" /> Deleting
            </div>
          ) : (
            'Delete'
          )
        }
        confirmClassName="!bg-red-500 hover:!bg-red-400"
        confirmDisabled={deleteConfirmText !== prompt?.name}
        icon={faSkullCrossbones}
        width="w-1/3"
        disabled={isPromptDeleting}
        onClose={() => setIsShowDeleteModal(false)}
        onConfirm={() => handleOnDelete()}>
        <div className="w-full">
          <div>
            <div>
              Are you sure you want to delete this prompt? This process is irreversible and{' '}
              <span className="font-extrabold">all data will be lost</span>.
            </div>
            <ul className="list-disc ml-4 mt-4 text-sm">
              <li>Prompt will be removed.</li>
              <li>All versions will be removed.</li>
              <li>All analytic and historic data will be removed.</li>
              <li>Collaborators will no longer have access to prompt.</li>
            </ul>
            <div className="mt-4">
              To continue, please type &quot;<span className="italic">{prompt?.name}</span>&quot; below.
            </div>
            <div className="mt-2">
              <input
                id="inviteemail"
                name="inviteEmail"
                type="email"
                autoComplete="off"
                value={deleteConfirmText}
                placeholder={prompt?.name}
                onChange={(e) => setDeleteConfirmText(e.currentTarget.value)}
                required
                disabled={isPromptDeleting}
                className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 my-5"
              />
            </div>
          </div>
        </div>
      </StyledDialog>
    </>
  );
};

export default ManagePrompt;
