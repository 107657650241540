import { useState, useEffect } from 'react';
import {
  faLayerGroup,
  faListCheck,
  faRocket,
  faSliders,
  faTableCells,
  faLifeRing,
  faFlask,
  faCircleQuestion
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generatePromptPayloadFromVersion, isLibraryItem } from '../../common/prompts';
import { Pipeline, PromptVersion } from '../../types';
import { Prompt, PromptVersionTypes } from '../../types/Prompt';
import CustomProperties from './CustomProperties';
import Playground from './Playground';
import PlaygroundMessages from './PlaygroundMessages';
import PromptDataStructure from './PromptDataStructure';
import PromptHelpersModal from './PromptHelpersModal';
import SamplePromptPayload from './SamplePromptPayload';
import { useNavigate } from 'react-router';

import './PromptActionToolbar.css';
import PromptPipelineRunner from './PromptPipelineRunner';

interface Props {
  prompt: Prompt | undefined;
  version: PromptVersion | undefined;
  pipelines: Pipeline[] | undefined;
  canEdit: boolean;
  disabled?: boolean;
  onUpdateField: (field: keyof PromptVersion, value: any) => void;
}

/**
 * PromptActionToolbar component provides a toolbar with various actions related to a prompt.
 *
 * @component
 * @param {Props} props - The properties passed to the component.
 * @param {Prompt} props.prompt - The prompt object.
 * @param {PromptVersion} props.version - The version of the prompt.
 * @param {boolean} props.canEdit - Flag indicating if the prompt can be edited.
 * @param {boolean} props.disabled - Flag indicating if the toolbar buttons should be disabled.
 * @param {function} props.onUpdateField - Callback function to update a field.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <PromptActionToolbar
 *   prompt={prompt}
 *   version={version}
 *   canEdit={true}
 *   disabled={false}
 *   onUpdateField={(field, value) => console.log(field, value)}
 * />
 */
const PromptActionToolbar: React.FC<Props> = ({
  prompt,
  version,
  pipelines,
  canEdit,
  disabled,
  onUpdateField
}: Props) => {
  const navigate = useNavigate();
  const [_prompt, setPrompt] = useState<Prompt>();
  const [_version, setVersion] = useState<PromptVersion>();
  const [_pipelines, setPipelines] = useState<Pipeline[]>();
  const [isShowDataStructure, setIsShowDataStructure] = useState<boolean>(false);
  const [isShowPlayground, setIsShowPlayground] = useState<boolean>(false);
  const [isSamplePayloadOpen, setIsSamplePayloadOpen] = useState<boolean>(false);
  const [isCustomPropertiesOpen, setIsCustomPropertiesOpen] = useState<boolean>(false);
  const [isShowHelpers, setIsShowHelpers] = useState<boolean>(false);
  const [isShowPipelineRunner, setIsShowPipelineRunner] = useState<boolean>(false);

  useEffect(() => {
    setPrompt(prompt);
    setVersion(version);
    setPipelines(pipelines);
  }, [prompt, version, pipelines]);

  return (
    <div className="toolbar-wrapper">
      <div className="flex">
        <div className="flex flex-1 flex-wrap gap-2">
          <button
            className="toolbar disabled:opacity-50"
            disabled={disabled}
            onClick={() => setIsSamplePayloadOpen(true)}>
            <FontAwesomeIcon icon={faTableCells} className="mr-1.5 inline-block" />
            Sample
          </button>
          <button
            className="toolbar disabled:opacity-50"
            disabled={disabled}
            onClick={() => setIsCustomPropertiesOpen(true)}>
            <FontAwesomeIcon icon={faSliders} className="mr-1.5 inline-block" />
            Properties
          </button>
          <button className="toolbar disabled:opacity-50" disabled={disabled} onClick={() => setIsShowHelpers(true)}>
            <FontAwesomeIcon icon={faLifeRing} className="mr-1.5 inline-block" />
            Helpers
          </button>
          <button className="toolbar disabled:opacity-50" disabled={disabled} onClick={() => setIsShowPlayground(true)}>
            <FontAwesomeIcon icon={faRocket} className="mr-1.5 inline-block" />
            Playground
          </button>
          <button
            className="toolbar disabled:opacity-50"
            disabled={disabled}
            onClick={() => setIsShowDataStructure(true)}>
            <FontAwesomeIcon icon={faLayerGroup} className="mr-1.5 inline-block" />
            Payload
          </button>
          <button
            className="toolbar disabled:opacity-50"
            disabled={disabled}
            onClick={() => setIsShowPipelineRunner(true)}>
            <FontAwesomeIcon icon={faFlask} className="mr-1.5 inline-block" />
            Pipeline
          </button>
        </div>
        <div>
          {!isLibraryItem(_prompt) && (
            <button
              className="toolbar disabled:opacity-50 mr-2"
              disabled={disabled}
              onClick={() => navigate(`/prompts/${_prompt?.id}/manage`)}>
              <FontAwesomeIcon icon={faListCheck} className="mr-1.5 inline-block" />
              Manage
            </button>
          )}
          <a href="/help/#/prompt_editor" target="_blank" rel="noreferrer">
            <button className="toolbar disabled:opacity-50 max-w-12" disabled={disabled}>
              <FontAwesomeIcon icon={faCircleQuestion} className="inline-block h-4 w-4" />
            </button>
          </a>
        </div>
      </div>
      <div>
        <PromptDataStructure
          isOpen={isShowDataStructure}
          version={_version}
          onClose={() => {
            setIsShowDataStructure(false);
          }}
        />
        {_version?.type === PromptVersionTypes.MESSAGING ? (
          <PlaygroundMessages
            open={isShowPlayground}
            version={_version}
            onClose={() => {
              setIsShowPlayground(false);
            }}
          />
        ) : (
          <Playground
            open={isShowPlayground}
            version={_version}
            onClose={() => {
              setIsShowPlayground(false);
            }}
          />
        )}
        <SamplePromptPayload
          prompt={_prompt}
          payload={_version?.samplePayload || (_version && generatePromptPayloadFromVersion(_version)) || {}}
          readOnly={!canEdit}
          isOpen={isSamplePayloadOpen}
          onSave={(_payload) => {
            setIsSamplePayloadOpen(false);
            onUpdateField('samplePayload', _payload);
          }}
          onClose={() => setIsSamplePayloadOpen(false)}
        />
        <CustomProperties
          properties={_version?.customProps}
          isOpen={isCustomPropertiesOpen}
          onClose={() => setIsCustomPropertiesOpen(false)}
          onSave={(props) => {
            setIsCustomPropertiesOpen(false);
            onUpdateField('customProps', props);
          }}
        />
        <PromptHelpersModal
          version={_version}
          isOpen={isShowHelpers}
          onClose={() => setIsShowHelpers(false)}
          onSave={(helpers) => {
            setIsShowHelpers(false);
            onUpdateField('helpers', helpers);
          }}
        />
        <PromptPipelineRunner
          prompt={_prompt}
          version={_version}
          pipelines={_pipelines}
          isOpen={isShowPipelineRunner}
          onClose={() => setIsShowPipelineRunner(false)}
        />
      </div>
    </div>
  );
};

export default PromptActionToolbar;
