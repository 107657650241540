import { faAnchor, faCircleQuestion, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// import { useNavigate } from 'react-router';
import { getErrorMessage } from '../../common/utils';
import { ManageWebhookModal, WebhookTile } from '../../components/webhooks';
import { getWebhooks } from '../../services/Webhooks';
import { Webhook } from '../../types/Webhooks';

interface Props {}

/**
 * Webhook List page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const WebhookList: React.FC<Props> = ({}: Props) => {
  //   const navigate = useNavigate();
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [webhooksFiltered, setWebhooksFiltered] = useState<Webhook[]>([]);
  const [isWebhooksLoading, setIsWebhooksLoading] = useState<boolean>(true);
  const [filterText, setFilterText] = useState<string>('');
  const [isManageWebhookModalOpen, setIsManageWebhookModalOpen] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setWebhooks(await getWebhooks());
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsWebhooksLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!webhooks) return;

    setWebhooksFiltered(
      webhooks.filter(
        (webhook) =>
          webhook.name.toLowerCase().includes(filterText.toLowerCase()) ||
          webhook.description.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [webhooks, filterText]);

  return (
    <div>
      <div className="mx-auto">
        <div className="text-right">
          <button className="standard" onClick={() => setIsManageWebhookModalOpen(true)}>
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            New Webhook
          </button>
          <a href="/help/#/pipelines_webhook_evaluators" target="_blank" rel="noreferrer">
            <button className="help">
              <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
            </button>
          </a>
        </div>
        {!isWebhooksLoading && !webhooks.length && (
          <div className="mt-5 text-gray-700">
            <div className="mt-2 text-indigo-500 mx-auto text-center">
              <FontAwesomeIcon icon={faAnchor} className="w-40 h-40" />
            </div>
            <div className="mt-5 text-gray-700 mx-auto text-center">
              It looks like you haven&lsquo;t created any webhooks yet.{' '}
              <a role="button" onClick={() => setIsManageWebhookModalOpen(true)} className="standard">
                Click here to get started!
              </a>
            </div>
          </div>
        )}
        {!isWebhooksLoading && webhooks.length > 0 && (
          <div className="flex items-center mt-2">
            <input
              id="filter"
              name="filter"
              type="search"
              autoComplete="off"
              value={filterText}
              placeholder="Filter"
              onChange={(e) => setFilterText(e.currentTarget.value)}
              required
              disabled={isWebhooksLoading}
              className="disabled:text-gray-400 block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
            />
          </div>
        )}
        <ul className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4 rounded-lg mt-6">
          {isWebhooksLoading && <WebhookTile />}
          {webhooksFiltered.map((webhook) => (
            <WebhookTile
              key={webhook.id}
              webhook={webhook}
              onUpdate={(newWebhook: Webhook) =>
                setWebhooks(webhooks.map((w) => (w.id === newWebhook.id ? newWebhook : w)))
              }
            />
          ))}
        </ul>
      </div>
      <ManageWebhookModal
        isOpen={isManageWebhookModalOpen}
        onClose={() => setIsManageWebhookModalOpen(false)}
        onSave={(webhook: Webhook) => {
          setWebhooks([webhook, ...webhooks]);
          setIsManageWebhookModalOpen(false);
        }}
      />
    </div>
  );
};

export default WebhookList;
